<template>
    <div class="openingsuren">

        <navigation></navigation>

        <div id="container">
            <div
                class="slider-wrap showcase om-section block-0"
                id="slider-9ae29c08a50641602b510d16b5084bcc">
                <ul
                    class="slider arrows arrows-hidden dots dots-default"
                    data-slider-speed="5000"
                    data-height="fixed-ratio"
                    data-att="35">
                    <li class>
                        <figure>
                            <img
                                alt="Brasserie Den Anker"
                                src="../assets/img/DenAnker6-min.jpg">
                            <figcaption>Brasserie Den Anker</figcaption>
                        </figure>
                    </li>
                </ul>
                <div class="overlay-wrap fixed-center">
                    <section class="overlay">
                        <h1>Openingsuren</h1>
                        <p>
                            <router-link
                                class="button"
                                to="/reserveren">Reserveren
                            </router-link>
                        </p>
                    </section>
                </div>
            </div>

            <div
                class="content-wrap content-form om-section block-1"
                id="content-form-9ae29c08a50641602b510d16b5084bcc"><a
                class="anchor scrollto-anchor"
                id="scrollto-anchor-1"></a>
                <section class="content">
                    <h1
                        style="margin-bottom: 30px"
                        class="entry-title"><span>Openingsuren</span></h1>
                    <div class="clearfix page-form-wrap form-position-right">
                        <div class="item-page-content">
                            <h2>Brasserie Den Anker</h2>
                        </div>
                    </div>
                    <p v-html="data.text"></p>
                    <article id="Openingsuren">
                        <section class="text-center">
                            <div class="Container u-paddingVertical--whole">
                                <div class="Row Row--bigGutter u-paddingVertical--half">
                                    <div class="Col--default--12 Col--medium--12 u-paddingVertical--half u-clearInnerMargin">
                                        <div class="u-hidden--small u-hidden--medium u-hidden--large">
                                            <table
                                                class="Table--openhours"
                                                style="margin-bottom:20px">
                                                <thead>
                                                <tr>
                                                    <td>Dag</td>
                                                    <td>Zaak</td>
                                                    <td>Keuken</td>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr class="6">
                                                    <td>Maandag</td>
                                                    <td>Gesloten</td>
                                                    <td>Gesloten</td>
                                                </tr>
                                                <tr class="6">
                                                    <td>Dinsdag</td>
                                                    <td>Gesloten</td>
                                                    <td>Gesloten</td>
                                                </tr>
                                                <tr class="6 is-active ">
                                                    <td>Woensdag</td>
                                                    <td>08:00u - 23:00u</td>
                                                    <td>10:00u - 21:00u</td>
                                                </tr>
                                                <tr class="6">
                                                    <td>Donderdag</td>
                                                    <td>11:00u - 23:00u</td>
                                                    <td>11:00u - 21:00u</td>
                                                </tr>
                                                <tr class="6">
                                                    <td>Vrijdag</td>
                                                    <td>11:00u - 23:00u</td>
                                                    <td>11:00u - 21:00u</td>
                                                </tr>
                                                <tr>
                                                    <td>Zaterdag</td>
                                                    <td>11:00u - 23:00u</td>
                                                    <td>11:00u - 21:00u</td>
                                                </tr>
                                                <tr class="6">
                                                    <td>Zondag</td>
                                                    <td>11:00u - 23:00u</td>
                                                    <td>11:00u - 21:00u</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <img
                                            src="../assets/img/openingsuren.jpg"
                                            alt="Openingsuren"
                                            class="Image--responsive u-hidden--extraSmall u-visible--small u-visible--medium u-visible--large"
                                            style="width:100%;">
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section class="Page-header Page-section Page-section--grayLight">
                            <div class="Container u-paddingVertical--double Text--center">
                                <a
                                    href="tel:+3214728352"
                                    class="Button Button--white Button--ghost Button--large u-displayMobile">Reserveren</a>
                                <router-link
                                    to="/reserveren"
                                    class="Button Button--white Button--ghost Button--large u-hideMobile">Reserveren
                                </router-link>
                            </div>
                        </section>

                    </article>
                </section>
            </div>

        </div>

        <app-footer></app-footer>

        <svg id="icon-sprite-social">
            <defs>
                <symbol id="icon-facebook">
                    <path d="M3.7 10h2.1V5h1.4l.2-1.7H5.8v-.9c0-.4 0-.7.7-.7h.9V0H5.9C4.3 0 3.7.8 3.7 2.2v1h-1V5h1v5z"/>
                </symbol>
            </defs>
        </svg>

    </div>
</template>

<script>
import AppFooter  from '../components/Footer'
import Navigation from '../components/Navigation'

export default {
    name: 'Contact',
    components: {Navigation, AppFooter},
    data() {
        return {
            isLoading: false,
            fullPage: true,
            data: [
                {title: '', subtitle: '', text: ''},
            ]
        }
    },
    mounted() {
        $(window).scrollTop(0)
        this.getWebsiteContent()
    },
    methods: {
        getWebsiteContent() {
            this.isLoading = true
            this.$axios.get('api/getWebsiteContentPage/Openingsuren').then(res => {
                if (res.data.error) {
                    //note: on error => mixin axiosCatch
                    this.errorMsg(res.data.error_msg)

                } else {
                    // note: on success
                    this.data      = res.data[0]
                    this.isLoading = false
                }
            }).catch(err => {
                // note: mixin axiosCatch
                this.log(err)

            })

        }
    }
}
</script>

<style scoped>
.slider-wrap figure {
    min-height: 65vh;
}

#container .slider-wrap figure img {
    height: 65vh !important;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
    display: block;
}

*, ::after, ::before {
    box-sizing: inherit;
}

.Page-header {
    padding-bottom: 11px;
    margin: 48px 0px 24px;
    border-bottom: 1px solid rgb(230, 229, 229);
}

.Page-header {
    padding-bottom: 0px;
    margin: 0px;
    border-bottom: 0px;
}

.Page-header {
    position: relative;
    margin: 0px;
    padding-bottom: 0px;
    border-bottom: 0px;
}

.Page-section.Page-section--grayLightest {
    background-color: rgb(240, 235, 232);
    color: rgb(69, 69, 69);
}

.Page-section.Page-section--grayLightest {
    color: rgb(69, 69, 69);
}

.Container {
    margin-right: auto;
    margin-left: auto;
    max-width: calc(1280px + 3rem);
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.Container::after, .Container::before {
    content: " ";
    display: table;
}

.Container::after {
    clear: both;
}

.u-paddingVertical--half[data-v-424c715b] {
    /* padding-right: 0.75em; */
    padding-left: 0.75em;
}

.App-footer-section > :first-child, .Dropdown--generical .Dropdown-menu > :first-child, .Page-section > :first-child, .Tab-content > .Tab-pane > :first-child, .u-clearInnerMargin > :first-child {
    margin-top: 0px;
}

.App-footer-section > :last-child, .Dropdown--generical .Dropdown-menu > :last-child, .Page-section > :last-child, .Tab-content > .Tab-pane > :last-child, .u-clearInnerMargin > :last-child {
    margin-bottom: 0px;
}

.Row {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
}

.Row::after, .Row::before {
    content: " ";
    display: table;
}

.Row::after {
    clear: both;
}

.Row.Row--bigGutter {
    margin-left: -3rem;
    margin-right: -3rem;
}

.Col--default--12, .Col--medium--12 {
    position: relative;
    min-height: 1px;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}

.Col--default--12 {
    float: left;
}

.Col--default--12 {
    width: 100%;
}

.Page-section.Page-section--grayLightest h1, .Page-section.Page-section--grayLightest h2 {
    color: rgb(69, 69, 69);
}

table {
    border-collapse: collapse;
    border-spacing: 0px;
}

table {
    background-color: transparent;
}

.Table--openhours {
    width: 100%;
}

td, th {
    padding: 0px;
}

.Table--openhours > tbody > tr > td, .Table--openhours > tfoot > tr > td, .Table--openhours > thead > tr > td {
    padding: 0.1875rem 0.375rem;
    border-top: none;
    border-bottom: none;
    border-left: none;
    border-image: initial;
    border-right: 1px solid rgb(128, 134, 140);
}

.Table--openhours > tbody > tr > td:last-child, .Table--openhours > tfoot > tr > td:last-child, .Table--openhours > thead > tr > td:last-child {
    border-right: none;
}

.Table--openhours > tbody > tr.is-active > td, .Table--openhours > tbody > tr.is-active > th, .Table--openhours > tfoot > tr.is-active > td, .Table--openhours > tfoot > tr.is-active > th, .Table--openhours > thead > tr.is-active > td, .Table--openhours > thead > tr.is-active > th {
    color: rgb(220, 209, 198);
}

img {
    border: 0px;
}

img {
    vertical-align: middle;
}

.Image--responsive {
    display: block;
    max-width: 100%;
    height: auto;
}

.u-visible--large, .u-visible--medium, .u-visible--small {
    display: none !important;
}

.Page-section.Page-section--grayLight {
    background-color: #1D1D1B;
    color: rgb(69, 69, 69);
}

.Page-section.Page-section--grayLight {
    color: rgb(69, 69, 69);
}

.Text--center {
    text-align: center;
}

.u-paddingVertical--double {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

a {
    background: 0px 0px;
}

a:active, a:hover {
    outline: 0px;
}

a:focus {
    outline: -webkit-focus-ring-color auto 5px;
    outline-offset: -2px;
}

.Button {
    display: inline-block;
    margin-bottom: 0px;
    font-weight: inherit;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    text-decoration: none;
    padding: 6px 12px;
    font-size: 16px;
    line-height: 1.5;
    user-select: none;
}

.Button.focus, .Button.is-active.focus, .Button.is-active:focus, .Button:active.focus, .Button:active:focus, .Button:focus {
    outline: -webkit-focus-ring-color auto 5px;
    outline-offset: -2px;
}

.Button.focus, .Button:focus, .Button:hover {
    color: rgb(51, 51, 51);
    text-decoration: none;
}

.Button.is-active, .Button:active {
    outline: 0px;
    background-image: none;
    box-shadow: rgba(0, 0, 0, 0.125) 0px 3px 5px inset;
}

.Button--large {
    padding: 10px 16px;
    font-size: 20px;
    line-height: 1.33;
}

.Button {
    font-family: Raleway, Helvetica, Arial, sans-serif;
    border: 0px solid transparent;
    text-transform: uppercase;
    padding: 0.375em 1.5em;
    font-size: 1em;
    line-height: 1.5;
}

.Button.is-active, .Button:active {
    outline: 0px;
    background-image: none;
}

.Button--white {
    color: rgb(189, 142, 100);
    background-color: rgb(255, 255, 255);
    border-color: rgb(255, 255, 255);
}

.Button--white.focus, .Button--white.is-active, .Button--white:active, .Button--white:focus, .Button--white:hover, .is-open .Dropdown-toggle .Button--white {
    background-color: rgb(235, 235, 235);
    color: rgb(174, 121, 74);
    border-color: rgb(235, 235, 235);
}


.Button--ghost {
    background: 0px 0px;
    border-width: 1px;
}

.Button--ghost.Button--white {
    color: rgb(255, 255, 255);
    background-color: transparent;
    border-color: rgb(255, 255, 255);
}

.Button--large {
    padding: 0.375em 1.5em;
    font-size: 1em;
    line-height: 1.5;
}

.u-displayMobile {
    display: inline-block;
}

.u-hideMobile {
    display: none;
}

@media (min-width: 1000px) {
    .Col--medium--1, .Col--medium--10, .Col--medium--11, .Col--medium--12, .Col--medium--2, .Col--medium--3, .Col--medium--4, .Col--medium--5, .Col--medium--6, .Col--medium--7, .Col--medium--8, .Col--medium--9 {
        float: left;
    }
}

@media (min-width: 1000px) {
    .Col--medium--12 {
        width: 100%;
    }
}

@media (max-width: 999px) and (min-width: 750px) {
    .u-hidden--small {
        display: none !important;
    }
}

@media (max-width: 1279px) and (min-width: 1000px) {
    .u-hidden--medium {
        display: none !important;
    }
}

@media (min-width: 1280px) {
    .u-hidden--large {
        display: none !important;
    }
}

@media (max-width: 999px) and (min-width: 750px) {
    .u-visible--small {
        display: table-cell !important;
    }
}

@media (max-width: 1279px) and (min-width: 1000px) {
    .u-visible--medium {
        display: table-cell !important;
    }
}

@media (min-width: 1280px) {
    .u-visible--large {
        display: table-cell !important;
    }
}

@media (max-width: 749px) {
    .u-hidden--extraSmall {
        display: none !important;
    }
}

@media (min-width: 750px) {
    .u-displayMobile {
        display: none;
    }
}

@media (min-width: 750px) {
    .u-hideMobile {
        display: inline-block;
    }
}

</style>
